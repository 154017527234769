<template>
  <div>
    <CRow>
      <CCol xs="12" sm="12" md="4" lg="4" xl="4">
        <CCard>
          <CCardHeader class="d-flex justify-content-between">
            <span><i class="fa fa-user"></i>&nbsp; Tipo de rol </span>
            <button
              @click="mtdBack"
              type="button"
              class="btn btn-sm text-white"
              style="background: #926cdc"
            >
              <i class="fas fa-hand-point-left"></i> Retroceder
            </button>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol xs="12" sm="12" md="12" lg="12">
                <cSelectForm
                  label="Seleccione Tipo Rol"
                  :options="data_roles"
                  placeholder="Escoja un Tipo Rol"
                  :value.sync="form_add_role.role_id"
                  @change="form_selected"
                />
              </CCol>
              <CCol xs="12" sm="12" md="12" lg="12">
                <button
                  type="button"
                  :disabled="vue.estado == 1 ? true : false"
                  style="background: #926cdc"
                  :class="btnClasses"
                  @click="loadTable"
                >
                  <i class="fas fa-search"></i>&nbsp; Buscar
                </button>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol v-if="vue.exp" xs="12" sm="12" md="8" lg="8" xl="8">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          caption="Lista de usuarios"
          icon="fas fa-users"
          btn_name="Usuario"
          @mtdBack="mtdBack"
          @show_modal="mtd_open_modal"
          @action_edit="mtd_show"
          @action_delete="mtd_delete"
          :size="'md'"
          :button_new="false"
          :actions="true"
          :withActions="'10%'"
          :buttonEdit="form_add_role.role_id != 5 ? true : false"
          :buttonDelete="form_add_role.role_id != 5 ? true : false"
          :myButtons="myButtons"
          @mtd_pass="mtd_pass"
          :buttonBack="false"
        />
      </CCol>
    </CRow>
    <!-- modal -->
    <CModalForm
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'md'"
      :title="modal.title"
      :button="cp_button"
      :show.sync="modal.modal_form"
      @mtd_action="mtd_action"
      :color="'info'"
      :centered="false"
    >
      <CRow>
        <CCol xs="12" sm="12" md="12" lg="12">
          <cSelectForm
            label="Seleccione Tipo Rol"
            :options="data_roles"
            placeholder="Escoja un Tipo Rol"
            :value.sync="users.role"
            @change="form_selected"
          />
        </CCol>
        <CCol xs="12" sm="12" md="12" lg="12">
          <CInput
            label="DNI"
            placeholder="Digite DNI"
            v-model="users.dni"
            maxlength="8"
            v-on:keypress="isNumber($event)"
          />
        </CCol>
        <CCol xs="12" sm="12" md="12" lg="12">
          <CInput
            label="Nombre"
            placeholder="Digite Nombre"
            v-model="users.name"
          />
        </CCol>
        <CCol xs="12" sm="12" md="12" lg="12">
          <CInput
            v-if="modal.action === 'store'"
            label="Apellido"
            placeholder="Digite Apellido"
            v-model="users.last_name"
          />
        </CCol>

        <CCol xs="12" sm="12" md="12" lg="12">
          <CInput
            label="Email"
            placeholder="Digite Email"
            v-model="users.email"
          />
        </CCol>
      </CRow>
    </CModalForm>

    <!-- modal password -->
    <CModalForm
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'md'"
      :title="modal.title"
      :button="cp_button_pass"
      :show.sync="modal.modal_form_pass"
      @mtd_action="mtd_action_pass"
      :color="'info'"
      :centered="true"
    >
      <CRow>
        <CCol sm="12">
          <CInput
            type="password"
            label="Nueva Contraseña"
            placeholder="Digite Contraseña"
            v-model="pass.password"
          />
        </CCol>
      </CRow>
    </CModalForm>

    <!-- modal delete -->
    <cModalDelete
      :backdrop="true"
      :closeOnBackdrop="true"
      :size="'md'"
      :title="'Borrar usuario'"
      :show.sync="modal_delete.boo"
      @mtd_commit="mtd_commit"
      :centered="true"
      :item="modal_delete.item"
    />
  </div>
</template>
<script>
const fields = [
  { key: "index", label: "Id", _style: "width:3%" },
  { key: "company_reason_social", label: 'Empresa', _style: "width:5%" },
  { key: "dni", label: "Documento", _style: "width:5%;" },
  { key: "name", label: "Nombre", _style: "width:15%;" },
  { key: "email", label: "Correo", _style: "width:15%;" },
];
import CTableWrapper from "../../components/shared/datatable/Table.vue";
import CModalForm from "../../components/shared/modals/cModalForm.vue";
import cModalDelete from "../../components/shared/modals/cModalDelete.vue";
import cSelectForm from "../../components/shared/inputs/cSelect.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";
export default {
  components: { CTableWrapper, CModalForm, cModalDelete, cSelectForm },
  data() {
    return {
      prefix: "users",
      vue: {
        exp: false,
        texto: "",
        estado: 1,
      },
      fields,
      data: [],
      myButtons: [
        {
          class: "btn-sm",
          color: "success",
          tooltip: "Cambiar contraseña",
          action: "mtd_pass",
          icon: "fas fa-key",
          // play: {
          //   type: "type_id_add_alternativas", //resource
          //   1: true,
          //   2: false,
          // },
        },
      ],
      data_roles: [],
      form_add_role: {
        role_id: "",
        permission_id: "",
        state: 1,
      },
      modal: {
        action: "",
        title: "",
        modal_form: false,
        modal_form_pass: false,
      },
      pass: {
        id: "",
        password: "",
        rol_id:"",
      },
      users: {
        id: "",
        role: "",
        dni: "",
        name: "",
        last_name: "",
        email: "",
      },
      modal_delete: {
        boo: false,
        item: [],
      },
    };
  },
  created() {
    this.mtd_getdata_typespermissions();
  },
  computed: {
    cp_button: function () {
      if (
        this.users.name != "" &&
        this.users.last_name != "" &&
        this.users.email != "")return false;
      return true;
    },
    cp_button_busqueda: function () {
      if (this.form_add_role.role_id != "") return false;
      return true;
    },
    cp_button_pass: function () {
      if (this.form_add_role.role_id != "" && this.pass.password != "") return false;
      return true;
    },
    btnClasses() {
      return [`btn text-white w-100`];
    },
  },
  methods: {
    ...mapActions(["get", "post"]),
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    mtdBack: function () {
      this.$emit("mtdBack");
    },
    form_selected: function () {
      this.vue.estado = 0;
    },
    mtd_getdata_typespermissions: function () {
      this.get({
        url: this.$store.getters.get__url + "/rol",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          let allRoles = response.data.roles;
          let userRoles = this.$store.getters.get_all_user.roles;
          if (userRoles.some(role => role.role.id === 1)) {
          this.data_roles = allRoles.map(role => ({
            label: role.name,
            value: role.id,
          }));
        }else {
          this.data_roles = allRoles
            .filter(role => role.id !== 1)
            .map(role => ({
              label: role.name,
              value: role.id,
            }));
          }
        })
        .catch((errors) => {});
    },
    mtd_getdata_captionrol: function (id) {
      this.get({
        url: this.$store.getters.get__url + "/rol/" + id + "/show",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.vue.texto = "Lista de Usuarios: " + response.name;
        })
        .catch((errors) => {});
    },
    mtd_getdata_usuariosxrol: function (id) {
      let url;
      if(this.$store.getters.get_company==null){
        url = this.$store.getters.get__url + "/" + this.prefix + "/byrol/" + id+ "/0" ;
      }else{
        url= this.$store.getters.get__url + "/" + this.prefix + "/byrol/" + id + "/" + this.$store.getters.get_company.id;
      }
      this.get({
        url: url,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data = response.data;
          let index = 1;
          this.data.forEach((arr)=>{
            arr.index = index;
            index++;
          })
          this.filterData(this.data)
        })
        .catch((errors) => {});
    },
    filterData(data){
      this.data.forEach((element) => {
        if (element.rol_id) {
          element.company_reason_social = element.company ? element.company.reason_social: '';
        }else{
          element.company_reason_social = "HOWERT";
        }
      });

    },
    loadTable: function () {
      let id = this.form_add_role.role_id;
      if (id != 0) {
        this.vue.exp = true;
      } else {
        this.vue.exp = false;
      }

      this.mtd_getdata_captionrol(id);
      this.mtd_getdata_usuariosxrol(id);
    },
    mtd_open_modal: function (boo, action) {
      this.modal.modal_form = boo;
      this.modal.action = action;
      if(action == "store"){
        this.modal.title = "Nuevo Usuario"
      }else{
        this.modal.title = "Editar Usuario";
      }

      if(action == "store"){
        this.users = {
            id: "",
            role: "",
            dni: "",
            name: "",
            last_name: "",
            email: "",
          }
      }else{
        this.users = this.users
      }
    },
    mtd_action: function () {
      this.post({
        url:
          this.$store.getters.get__url +
          "/" +
          this.prefix +
          "/" +
          this.modal.action,
        token: this.$store.getters.get__token,
        params: this.users,
      })
        .then((response) => {
          let color = "success";
          let message = response.message;
          if (!response.error) {
            if (response.statusCode == 202) {
              color = "warning";
            } else {
              if (this.modal.action == "store") {
                this.data.push(response.data.user);
              } else {
                this.data.forEach((element) => {
                  if (element.id == response.data.user.id) {
                    element.name = response.data.user.name;
                    element.dni = response.data.user.dni;
                    element.email = response.data.user.email;
                  }
                });
                this.mtd_getdata_usuariosxrol(this.form_add_role.role_id);
              }
            }
          } else {
            color = "danger";
          }
          this.modal = {
            action: "",
            title: "",
            modal_form: false,
            name: "",
            last_name: "",
            document_type: "",
            dni: "",
            rol_id: "",
            email: "",
          };
          bus.$emit("alert", {
            color: color,
            message: message,
          });
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_pass: function (id) {
      this.modal.modal_form_pass = true;
      this.modal.title = "Cambiar Contraseña";
      this.pass.id = id.id;
      this.pass.rol_id = id.rol_id;
      this.pass.password="";
    },
    mtd_action_pass: function () {
      let color = "success";
      let message = "";
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/new_password",
        token: this.$store.getters.get__token,
        params: this.pass,
      })
        .then((response) => {
          message = "REGISTRADO CORRECTAMENTE.";

          this.modal = {
            action: "",
            title: "",
            modal_form: false,
            id: "",
            password: "",
          };
          bus.$emit("alert", {
            color: color,
            message: message,
          });
        })
        .catch((errors) => {
          bus.$emit("alert", {
            color: "danger",
            message: errors,
          });
        });
    },
    mtd_show: function (id) {
      this.get({
        url:
          this.$store.getters.get__url + "/" + this.prefix + "/show/" + id +"/"+this.form_add_role.role_id,
          token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.users = response;
          this.users.role = response.roles[0].role_id;
          this.users.company = this.$store.getters.get_company;
          this.mtd_open_modal(true, "update");
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_delete: function (item) {
      this.modal_delete.boo = true;
      this.modal_delete.item = item;
    },
    close_delete: function () {
      this.modal_delete.boo = false;
    },
    mtd_commit: function () {
      this.post({
        url: this.$store.getters.get__url + "/" + this.prefix + "/destroy",
        token: this.$store.getters.get__token,
        params: {
          id: this.modal_delete.item.id,
        },
      })
        .then((response) => {
          let temp = [];
          if (!response.error && response.statusCode == 200) {
            this.data.forEach((element) => {
              if (element.id != this.modal_delete.item.id) {
                temp.push(element);
              }
            });
            this.data = temp;
            this.modal_delete = {
              boo: false,
              item: [],
            };
            this.mtd_getdata_usuariosxrol(this.form_add_role.role_id);
            bus.$emit("alert", {
              color: "success",
              message: response.message,
            });
          } else {
            bus.$emit("alert", {
              color: "danger",
              message: response.message,
            });
          }
        })
        .catch((errors) => {
          console.log(errors);
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-search {
  color: #fff;
  background-color: #2819ae;
  border-color: #2517a3;
}
</style>
